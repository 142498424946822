@font-face {
  font-family: 'Gotham';
  src: url('./config/theme/fonts/Gotham-Book.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  src: url('./config/theme/fonts/Gotham-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Gotham';
  src: url('./config/theme/fonts/Gotham-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Gotham';
  src: url('./config/theme/fonts/Gotham-Black.otf') format('opentype');
  font-style: normal;
  font-weight: 900;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Gotham', sans-serif !important;
}

body {
  font-family: 'Gotham', sans-serif !important;
  /*
    At the moment minimal supported screen width is 64rem (1024px)
  */
  min-width: 64rem;
}

delta-dental {
  display: block;
  min-height: 100%;
}
